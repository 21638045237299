import * as React from "react"
import { ContactStyles } from "./ContactStyles"
import ContactForm from "../ContactModule/ContactForm"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <ContactForm />
    </ContactStyles>
  )
}

export default Contact
